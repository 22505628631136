<template>
    <b-card no-body class="p-1">
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <div class="demo-spacing-0 d-flex justify-content-between m-1" dir="rtl">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
                    <feather-icon size="16" icon="FilterIcon"/>
                    فیلتر
                </b-button>
                <b-button v-if="$havePermission('CREATE_ADMINS')" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="info" @click="addNewIsActive=true">
                    <feather-icon size="16" icon="UserPlusIcon"/>
                    افزودن مدیر
                </b-button>
            </div>
            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"
                    @filter="getData(1, perPage)"
                    @reset="getData(1, perPage)"/>

            <add-new-admin :is-active.sync="addNewIsActive" :roles="this.columns[5].selectOptions"
                           @added="getData(1, perPage)"/>

            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        @sort-changed="sort($event)"
                        no-local-sort
                        class="text-nowrap"
                        style="min-height : 235px"
                >
                    <template #cell(createdAtDateTime)="data">
                <span dir="ltr">
                    {{$G2J(data.item.createdAtDateTime)}}
                </span>
                    </template>

                    <template #cell(role)="data">
                        <div class="text-nowrap d-inline-flex align-middle">
                            <feather-icon
                                    :icon="data.item.role.iconName"
                                    size="18"
                                    class="mr-50"
                                    :class="`text-${data.item.role.color}`"
                            />
                            <span class="align-text-top text-capitalize">{{ data.item.role.persianName }}</span>
                        </div>
                    </template>

                    <template #cell(isActive)="data">
                        <b-badge
                                pill
                                :variant="'light-'+statusVariant(data.item.isActive)"
                                class="text-capitalize"
                        >
                            {{statusLabel(data.item.isActive)}}
                        </b-badge>
                    </template>

                    <template #cell(action)="{item,index}">
                        <template v-if="$havePermission('DETAILS_ADMINS') ||
                         $havePermission('UPDATE_ADMINS') ||
                          $havePermission('LIST_MESSAGES')"
                        >
                            <b-dropdown
                                    id="dropdown-offset"
                                    variant="link"
                                    no-caret
                                    offset="80px"
                                    :right="true"
                            >
                                <template #button-content>
                                    <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="align-middle text-body"
                                    />
                                </template>
                                <b-dropdown-item v-if="$havePermission('DETAILS_ADMINS')"
                                                 :to="{ name: 'admin-profile', params: { id: item.id } }">
                                    <feather-icon icon="EditIcon"/>
                                    <span class="align-middle ml-50">جزییات</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('UPDATE_ADMINS')" @click="changeStatus(index)">
                                    <feather-icon icon="RefreshCwIcon"/>
                                    <span class="align-middle ml-50">تغییر وضعیت</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('LIST_MESSAGES')">
                                    <feather-icon icon="MessageSquareIcon" :badge="5"
                                                  badge-classes="badge-info test-badge"/>
                                    <span class="align-middle ml-50">پیام ها</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                        <not-allowed v-else/>
                    </template>
                </b-table>
            </div>

            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
                    dir="rtl"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>
                <div>
                    <label for="perpage">تعداد در صفحه</label>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(1,perPage)"
                    />
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
    import {
        BPagination,
        BCard,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BBadge,
        BOverlay,
    } from 'bootstrap-vue'
    import SearchAndFilter from "@/layouts/components/SearchAndFilter";
    import vSelect from 'vue-select';
    import AddNewAdmin from "@/views/Admins/addNewAdmin";
    import NotAllowed from "@/layouts/components/NotAllowed";

    export default {
        name: 'AdminAccounts',
        components: {
            NotAllowed,
            AddNewAdmin,
            SearchAndFilter,
            BPagination,
            BCard,
            BButton,
            BTable,
            BDropdown,
            BDropdownItem,
            BBadge,
            vSelect,
            BOverlay,
        },
        data: () => ({
            test: 0,
            pageLength: 3,
            dir: false,
            searchTerm: '',
            currentPage: 1,
            perPage: 10,
            rows: 200,
            sortBy: '',
            isSortDirDesc: '',
            userData: [],
            isActive: false,
            addNewIsActive: false,
            perPageOptions: [5, 10, 20, 50, 100],
            items: [],
            columns: [
                {
                    label: 'نام',
                    key: 'firstName',
                    sortable: false,
                    searchType: 'text'
                },
                {
                    label: 'خانوادگی',
                    key: 'lastName',
                    sortable: false,
                    searchType: 'text'
                },
                {
                    label: 'نام کاربری',
                    key: 'email',
                    sortable: false,
                    searchType: 'text'
                },
                {
                    label: 'تلفن',
                    key: 'mobileNumber',
                    sortable: false,
                    searchType: 'text'
                },
                {
                    label: 'تاریخ ساخت اکانت',
                    key: 'createdAtDateTime',
                    sortable: true,
                    searchType: 'date'
                },
                {
                    label: 'نقش',
                    key: 'role',
                    sortable: true,
                    searchType: 'select',
                    selectOptions: []
                },
                {
                    label: 'وضعیت',
                    key: 'isActive',
                    sortable: true,
                    searchType: 'select',
                    selectOptions: [
                        {label: 'غیر فعال', value: false},
                        {label: 'فعال', value: true}
                    ]
                },
                {
                    label: 'مدیریت',
                    key: 'action'
                },
            ],
        }),
        computed: {
            statusVariant() {
                return e => e ? 'success' : 'danger';
            },
            statusLabel() {
                return e => e ? 'فعال' : 'غیر فعال';
            },
        },
        methods: {
            changeStatus(e) {
                console.log(this.items[e].status)
                this.$swal({
                    title: 'آیا از تغییر وضعیت مدیر مطمئن هستید؟',
                    // text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'بله',
                    cancelButtonText: 'خیر',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then(async (result) => {
                    if (result.value) {
                        console.warn('change active')
                        this.state.loading = true
                        const address = '/admins/' + (this.items[e].isActive ? 'de' : '') + 'activate/' + this.items[e].id
                        const res = await this.$axios.post(address)

                        this.items[e].isActive = !res.data.message.includes('Deactivated')

                        await this.getData(this.currentPage, this.perPage)

                        this.$swal({
                            icon: 'success',
                            title: this.items[e].isActive ? 'مدیر فعال شد' : 'مدیر غیر فعال شد',
                            confirmButtonText: 'تایید',
                            // text: 'Your file has been deleted.',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    }
                })
            },

            sort(e) {
                console.log(e)

                let sort = this.$toSnakeCase(e.sortBy)
                // let sort = e.sortBy
                let sorting = e.sortDesc ? 'DESC' : 'ASC'

                this.$router.push({
                    query: {
                        ...this.$route.query,
                        orderBy: sort,
                        sorting: sorting
                    }
                })

                this.getData(1, this.perPage)

            },
            async getRoles() {
                const res = await this.$axios('/roles')
                let allRoles = []
                for (let object of res.data.content) {
                    allRoles.push({
                        label: object.persianName,
                        value: object.englishName
                    })
                }
                this.columns[5].selectOptions = allRoles
                console.log('aaaaa', this.columns[5])
            },

            async getData(page, perPage) {
                const queryParams = {
                    size: perPage,
                    page: page,
                    ...this.$route.query
                }
                this.state.loading = true
                const res = await this.$axios('/admins', {params: queryParams})
                this.state.loading = false
                this.items = res.data.content
                this.currentPage = res.data.number + 1
                this.rows = res.data.totalElements
            },

        },
        created() {
            this.getRoles()
            this.getData()
        }
    }
</script>
<style lang="scss">
    [v-cloak] {
        opacity: 0;
    }

    [dir="rtl"] .test-badge {
        font-size: 10px;
        top: -6px;
        left: -5px !important;
        min-width: 16px;
        min-height: 16px;
    }
</style>
